import { transformDataSubTitle } from './transform-data-sub-title';

import { BUTTON_TEXT, ButtonType } from '~/constants/button';
import { ENTER } from '~/constants/enter';
import { stepName } from '~/constants/step-name';
import { TitleTextLinkEndType } from '~/shared/text-link-end/text-link-end';
import { FrontConfigType } from '~/utils/get-front-config/type';

type ScreenDataType = {
    titleBottom: string;
    buttonTypeBottom?: ButtonType;
    screenTitle?: TitleTextLinkEndType;
    screenSubTitle?: TitleTextLinkEndType;
    buttonTypeTop?: ButtonType;
    titleTop?: string;
};

export const getDataForRender = (step?: string, frontConfig?: FrontConfigType): ScreenDataType => {
    switch (step) {
        case stepName.one: {
            return {
                titleTop: BUTTON_TEXT.publicServices,
                titleBottom: BUTTON_TEXT.electronicSignature,
                buttonTypeTop: ButtonType.button,
                buttonTypeBottom: ButtonType.button,
            };
        }

        case stepName.two: {
            return {
                screenTitle: ENTER.installExtensionTitle,
                screenSubTitle: transformDataSubTitle(ENTER.readInstructions, frontConfig),
                titleTop: BUTTON_TEXT.installExtension,
                titleBottom: BUTTON_TEXT.otherLogin,
                buttonTypeTop: ButtonType.link,
                buttonTypeBottom: ButtonType.button,
            };
        }

        case stepName.three: {
            return {
                screenTitle: ENTER.downloadPluginTitle,
                screenSubTitle: transformDataSubTitle(ENTER.readInstructions, frontConfig),
                titleTop: BUTTON_TEXT.downloadPlugin,
                titleBottom: BUTTON_TEXT.otherLogin,
                buttonTypeTop: ButtonType.button,
                buttonTypeBottom: ButtonType.button,
            };
        }
        case stepName.four: {
            return {
                screenTitle: ENTER.connectElectronicSignature,
                titleTop: BUTTON_TEXT.checkElectronicSignature,
                titleBottom: BUTTON_TEXT.otherLogin,
                buttonTypeTop: ButtonType.button,
                buttonTypeBottom: ButtonType.button,
            };
        }
        case stepName.five: {
            return {
                screenTitle: ENTER.selectCertificate,
                titleBottom: BUTTON_TEXT.otherLogin,
                buttonTypeBottom: ButtonType.button,
            };
        }

        default:
            return {
                titleBottom: '',
            };
    }
};
