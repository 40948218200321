import { PluginError } from './plugin';

import { ErrorText } from '~/types/errors';

export enum ModalType {
    revoke = 'revoke',
    viewAnswer = 'viewAnswer',
    generateKeys = 'generateKeys',
}
export const MODAL_TYPE = {
    revoke: ModalType.revoke,
    viewAnswer: ModalType.viewAnswer,
    generateKeys: ModalType.generateKeys,
};

export enum AuthErrorMessage {
    revoked = 'revoked',
    notPKI = "didn't find",
}

export enum ModalTextVariant {
    generateKeys = 'Для генерации ключевой пары подключите съемный носитель (USB Flash/USB-Token). На\u00A0нём будет создан контейнер с\u00A0электронными закрытыми ключами, необходимым для\u00A0установки сертификата. Сертификат можно будет установить во\u00A0время посещения Банка (возьмите USB-носитель с\u00A0собой), либо сделать это\u00A0самостоятельно в\u00A0личном кабинете, после посещения\u00A0Банка.',
    installOk = 'Сертификат успешно установлен',
    isNotInPersonalStorage = 'Сертификат не установлен в личное хранилище',
    keyUndefined = 'Контейнер с ключами не обнаружен',
    keyOrContainerUndefined = 'Контейнер не обнаружен либо сертификат не установлен в контейнер',
    incorrectParameter = 'Параметр задан неверно',
    addContainer = 'Вставьте носитель с контейнером',
    createRequest = 'Новый сертификат',
    createDraft = 'Черновик',
    certificateIsNotValid = 'Сертификат отозван. Авторизуйтесь по другому сертификату',
    certificateDidNotFind = 'Вход по данному сертификату не возможен. Выберите другой сертификат либо авторизуйтесь через Госуслуги.',
    tryAgain = 'Попробуйте еще раз',
    error = 'Ошибка',
    cancel = 'Действие было отменено пользователем',
    dataReset = 'Данные будут сброшены. Вы уверены, что хотите перейти?',
    needExtension = 'Включите расширение браузера CryptoPro Extension for CAdES Browser Plug-in и перезагрузите страницу',
    answer = 'Результаты проверок',
    removeDraft = 'Черновик был успешно удалён',
    errorTemplateMessage = 'Удостоверяющий центр больше не выпускает сертификат с таким назначением. Пожалуйста, удалите черновик и создайте новую заявку на сертификат.',
    attention = 'Внимание!',
    needExtensionTitle = 'Отсутствует расширение браузера',
    certificateIsNotValidTitle = 'Сертификат отозван',
    certificateDidNotFindTitle = 'Неизвестный сертификат',
}

export const ERROR_TEXTS: ErrorText = {
    [PluginError.cancel]: ModalTextVariant.cancel,
    [PluginError.cancelInInstall]: ModalTextVariant.cancel,
    [PluginError.isNotInPersonalStorage]: ModalTextVariant.isNotInPersonalStorage,
    [PluginError.keyUndefined]: ModalTextVariant.keyUndefined,
    [PluginError.incorrectParameter]: ModalTextVariant.incorrectParameter,
    [PluginError.lostConnection]: ModalTextVariant.needExtension,
    [PluginError.containerNotFound]: ModalTextVariant.keyUndefined,
    [PluginError.internalError]: ModalTextVariant.keyUndefined,
    default: ModalTextVariant.addContainer,
};
