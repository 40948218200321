import { Fragment } from 'react';
import { Alert, Space, Typography } from 'antd';
import { FormWrapper } from 'shared/form-components/form-wrapper';

import { SignForm } from './components/sign-form';
import { useSignDocument } from './hooks/use-sign-document';

import styles from './sign-document.module.css';

import { ViewAnswerModal } from '~/components/view-answer-modal';
import { BUTTON_TEXT } from '~/constants/button';
import { SignDocumentText } from '~/constants/sign-document';
import { WithLoader } from '~/hoc/with-loader';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';

export const SignDocument = () => {
    const {
        form,
        isShowResultButton,
        downloadSignedFile,
        resetFileField,
        isShowForm,
        isWarning,
        warningText,
        isLoading,
        userCertificates,
        initialCertificate,
        onSignDocument,
        isDisabledSignButton,
        modal,
        modalCloseHandler,
    } = useSignDocument();

    return (
        <WithLoader isLoading={isLoading} className={styles.spinner}>
            <FormWrapper
                header={
                    <Fragment>
                        <Typography.Title className={styles.title} level={5}>
                            {SignDocumentText.header}
                        </Typography.Title>
                        <Alert
                            type='warning'
                            showIcon={true}
                            message={SignDocumentText.attention}
                            className={styles.alert}
                        />
                    </Fragment>
                }
                footer={
                    isShowResultButton && (
                        <Space>
                            <CustomButton
                                className='applicationTabButton'
                                onClick={downloadSignedFile}
                            >
                                {BUTTON_TEXT.downloadSignedFile}
                            </CustomButton>
                            <CustomButton className='applicationTabButton' onClick={resetFileField}>
                                {BUTTON_TEXT.signNewFile}
                            </CustomButton>
                        </Space>
                    )
                }
            >
                {isShowForm && (
                    <SignForm
                        userCertificates={userCertificates}
                        form={form}
                        initialCertificate={initialCertificate || ''}
                        onSignDocument={onSignDocument}
                        isDisabledSignButton={isDisabledSignButton}
                        isDisabledSelect={isShowResultButton}
                    />
                )}
                {isWarning && (
                    <Alert
                        type='error'
                        showIcon={true}
                        message={warningText}
                        className={styles.alert}
                    />
                )}
            </FormWrapper>
            <CustomModal
                open={modal.isError}
                setOpenModal={modalCloseHandler}
                isClose={modal.isError}
                footer={null}
            >
                <ViewAnswerModal isError={true} text={modal.text} title={modal.title} />
            </CustomModal>
        </WithLoader>
    );
};
