import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import { Rule } from 'antd/es/form';
import { CustomFormItem } from 'shared/form-components/custom-form-item';

import styles from './custom-select.module.css';

import { useCustomSelectProps } from '~/hooks/use-custom-props';
import { CustomSpace } from '~/shared/custom-space';
import { CustomTooltip } from '~/shared/custom-tooltip';

export type CustomSelectOption = {
    value: string | number;
    label: string;
};
type CustomSelectProps = {
    options?: CustomSelectOption[];
    initialValue?: string | number;
    tooltipInfoTitle?: string;
    currentValue?: string | number;
    label?: string;
    keyName?: string;
    itemName?: string;
    rules?: Rule[];
    disabled?: boolean;
};

export const CustomSelect = ({
    initialValue,
    options,
    tooltipInfoTitle,
    currentValue,
    label,
    keyName,
    itemName,
    rules,
    disabled,
    ...restProps
}: CustomSelectProps) => {
    const filterOption = (input: string, option?: { label: string; value: string | number }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const { titleForTooltip, placeholder } = useCustomSelectProps(
        currentValue,
        disabled || false,
        label,
        itemName,
    );

    return tooltipInfoTitle ? (
        <CustomSpace direction='horizontal' size={5} className={styles.customLabel}>
            <CustomFormItem
                name={itemName || keyName}
                label={label}
                rules={rules}
                initialValue={initialValue}
                {...restProps}
                className={styles.select}
            >
                <Select
                    options={options}
                    popupMatchSelectWidth={false}
                    showSearch={true}
                    filterOption={filterOption}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </CustomFormItem>
            {tooltipInfoTitle && (
                <Tooltip title={tooltipInfoTitle}>
                    <InfoCircleOutlined className={styles.icon} />
                </Tooltip>
            )}
        </CustomSpace>
    ) : (
        <CustomTooltip title={titleForTooltip}>
            <CustomFormItem
                name={keyName}
                label={label}
                rules={rules}
                initialValue={initialValue}
                {...restProps}
                className={styles.select}
            >
                <Select
                    options={options}
                    popupMatchSelectWidth={false}
                    showSearch={true}
                    filterOption={filterOption}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </CustomFormItem>
        </CustomTooltip>
    );
};
