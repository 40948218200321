import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface';

import { RangePickerDropdown } from '../../certificates-table/range-picker-dropdown';

import { ActionVariantsTypeArchive, ARCHIVE_ACTIONS } from '~/constants/archive';
import { CERTIFICATES_STATUS_INFO, StatusCode } from '~/constants/certificates';
import { CERTIFICATES_COLUMNS, CertificatesColumnsName } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { SendToArchiveRequest } from '~/rtk-queries/types/certificates';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomTag } from '~/shared/custom-tag';
import { getRequestData } from '~/utils/get-request-data';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

type ReturnColumnsForArchiveTableType = {
    allTitles: ColumnFilterItem[];
    unarchiveRequest: (request: SendToArchiveRequest) => void;
};
export const returnColumnsForArchiveTable = ({
    allTitles,
    unarchiveRequest,
}: ReturnColumnsForArchiveTableType): ColumnsType<CurrentPageTable> => {
    const filterDropdown = ({ ...props }: FilterDropdownProps) => (
        <RangePickerDropdown filterName={LOCAL_STORAGE.filtersForArchives} {...props} />
    );

    const unarchive = (data: CurrentPageTable) => {
        const requestData = getRequestData(data);

        unarchiveRequest(requestData);
    };

    return [
        {
            key: CertificatesColumnsName.title,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.title],
            dataIndex: CertificatesColumnsName.title,
            filters: allTitles,
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (text) => <CertificatesTitle title={text} />,
        },
        {
            key: CertificatesColumnsName.statusTag,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.statusTag],
            dataIndex: CertificatesColumnsName.statusTag,
            filters: Object.keys(CERTIFICATES_STATUS_INFO)
                .filter(
                    (_, idx) =>
                        idx !== StatusCode.ApplicationCompleted && idx !== StatusCode.Draft - 1,
                )
                .map((element) => ({
                    text: CERTIFICATES_STATUS_INFO[Number(element) as StatusCode].titleStatus,
                    value: Number(element),
                })),
            filterOnClose: false,
            filterSearch: true,
            sorter: true,
            render: (item) => <CustomTag title={item.titleStatus} color={item.color} />,
        },
        {
            key: CertificatesColumnsName.lastEditDate,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.lastEditDate],
            dataIndex: CertificatesColumnsName.lastEditDate,
            filterDropdown,
            filterSearch: true,
            sorter: true,
            width: 'var(--width-for-column-150)',
        },
        {
            key: CertificatesColumnsName.actionsVariant,
            title: CERTIFICATES_COLUMNS[CertificatesColumnsName.actionsVariant],
            dataIndex: CertificatesColumnsName.actionsVariant,
            render: (_, item) => (
                <Typography.Link onClick={() => unarchive(item)}>
                    {ARCHIVE_ACTIONS[ActionVariantsTypeArchive.returnToList]}
                </Typography.Link>
            ),
            width: 'var(--width-for-column-220)',
        },
    ];
};
