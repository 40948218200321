import { Dayjs } from 'dayjs';

import { FormTypes } from '../types/form-types';

import { UserInfo } from '~/rtk-queries/types/private-room';
import { Nullable } from '~/types/common';
import { UserInfoDataValue } from '~/types/user-info';

export const transformFormValuesFromSubmit = (
    formValues: Nullable<Partial<FormTypes>>,
    userInfo?: UserInfo,
) => {
    const userInfoAllDataArr = Object.entries({
        ...userInfo?.address,
        ...userInfo?.main,
        ...userInfo?.other,
    });

    const formValuesArr = Object.entries(formValues);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transformArr = [] as (string | number | Dayjs | null | any)[];

    userInfoAllDataArr.forEach(([keyData, valueData]) => {
        const { enabled } = valueData as UserInfoDataValue;

        formValuesArr.forEach(([keyFormValues, valueFormValues]) => {
            if (keyFormValues === keyData && enabled) {
                transformArr.push([keyFormValues, valueFormValues]);
            }
        });
    });

    return Object.fromEntries(transformArr);
};
