export enum FrontConfigKey {
    CadesInstructionLink = 'CadesInstructionLink',
    ContactLinkCall = 'ContactLinkCall',
    ContactLinkFeedback = 'ContactLinkFeedback',
    ContactLinkMail = 'ContactLinkMail',
    ContactLinkTelegram = 'ContactLinkTelegram',
    ContactLinkViber = 'ContactLinkViber',
    ContactLinkWhatsApp = 'ContactLinkWhatsApp',
    ExtentionLinkChrome = 'ExtentionLinkChrome',
    ExtentionLinkDefault = 'ExtentionLinkDefault',
    ExtentionLinkEdge = 'ExtentionLinkEdge',
    ExtentionLinkFirefox = 'ExtentionLinkFirefox',
    ExtentionLinkIe = 'ExtentionLinkIe',
    ExtentionLinkMsie = 'ExtentionLinkMsie',
    ExtentionLinkOpera = 'ExtentionLinkOpera',
    ExtentionLinkYandex = 'ExtentionLinkYandex',
    InstructionLinkAurora = 'InstructionLinkAurora',
    InstructionLinkDefault = 'InstructionLinkDefault',
    InstructionLinkLinux = 'InstructionLinkLinux',
    InstructionLinkMacOS = 'InstructionLinkMacOS',
    InstructionLinkWindows = 'InstructionLinkWindows',
    PSBSignatureLink = 'PSBSignatureLink',
    UserDocs = 'UserDocs',
    MCHDLink = 'MCHDLink',
}

export type FrontConfigType = { [key in FrontConfigKey]: string };
