export const DEFAULT_PAGE = 1;
export const DEFAULT_SIZE = 15;
export const DEFAULT_FILE_SIZE = 100;
export const FORMAT_DATE_1 = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const FORMAT_DATE_LOCALE = 'DD.MM.YYYY HH:mm:ss';
export const SORT_ORDERS = {
    ascend: 'asc',
    descend: 'desc',
};
export const DATE_FORMAT = 'DD.MM.YYYY';
export const VALIDATION_RULES = {
    required: {
        required: true,
        message: 'Обязательно для заполнения',
    },
};
export const VALIDATION_FIELD_RULES = [{ required: true, message: '' }];

export const CERTIFICATE_FORMAT = '.cer,.crt,.p7b,.pem';
