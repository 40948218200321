import { CurrentPageTable } from './transform-certificates-data/type';
import {
    isApplicationData,
    isCertificateData,
    isPreApplicationData,
} from './is-application-data-type';

import { LifeStatus } from '~/constants/certificates-table';
import { SendToArchiveRequest } from '~/rtk-queries/types/certificates';

export const getRequestData = (data: CurrentPageTable): SendToArchiveRequest => {
    const { lifeStatus } = data;

    const requestData: SendToArchiveRequest = { lifeStatus };

    if (isPreApplicationData(data)) {
        requestData.lkRequestId = data.lkRequestId;
    }

    if (isApplicationData(data)) {
        requestData.pkiEntityId = data.pkiEntityId || data.certRequestId;
    }

    if (isCertificateData(data) && data.lifeStatus === LifeStatus.certificate) {
        requestData.pkiEntityId = data.certificateId || data.pkiEntityId || data.certRequestId;
    }

    if (isCertificateData(data) && data.lifeStatus === LifeStatus.revocation) {
        if (data.lkRevokeRequestId) {
            requestData.lkRevokeRequestId = data.lkRevokeRequestId;
        } else {
            requestData.pkiEntityId = data.certificateId || data.revokeRequestId;
        }
    }

    return requestData;
};
