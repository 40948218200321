import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Empty, Typography } from 'antd';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';

import { RevokeReasonModal } from '../revoke-reason-modal';
import { ViewAnswerModal } from '../view-answer-modal';

import { returnColumnsForCertificatesTable } from './constants/return-columns-for-certificates-table';
import { useGetFilters } from './hooks/use-get-filters';

import styles from './certificates-table.module.css';

import { BUTTON_TEXT } from '~/constants/button';
import { ActionVariantsType, PrintTemplates } from '~/constants/certificates';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ModalTextVariant, ModalType } from '~/constants/modal';
import { REVOKE_CERTIFICATE } from '~/constants/revoke-certificate';
import { useCertificateAction } from '~/hooks/use-certificates-actions';
import { startObj, useHookForPagination } from '~/hooks/use-hook-for-pagination';
import { usePrintDocument } from '~/hooks/use-print-document';
import { PrivateRoomTabsName } from '~/pages/private-room/constants/tabs-name';
import { useGetCertificatesListQuery } from '~/rtk-queries/endpoints/certificates-list';
import { CertificatesTableResponse } from '~/rtk-queries/types/certificates';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { CustomTable } from '~/shared/custom-table/custom-table';
import { TableFilters } from '~/types/common';
import { CurrentPageTable } from '~/utils/transform-certificates-data/type';

const { Title } = Typography;

dayjs.extend(buddhistEra);

export const CertificatesTable = () => {
    const { tabName } = useParams();
    const filtersForCertificates = useGetFilters(LOCAL_STORAGE.filtersForCertificates);

    const [currentRevokeReason, setCurrentRevokeReason] = useState(0);
    const [currentCertificateData, setCurrentCertificateData] = useState<CurrentPageTable>(
        {} as CurrentPageTable,
    );

    const {
        actionHandler,
        isOpenModal,
        setIsOpenModal,
        revokeRequest,
        generateKeys,
        modalInfo,
        isFetchingDetails,
        isRevokeRequest,
        setIsRevokeRequest,
    } = useCertificateAction(currentRevokeReason, currentCertificateData);

    const {
        searchParamsObj,
        isEmptyParamObj,
        currentTableSize,
        currentTablePage,
        currentSortOrder,
        currentSortColumn,
    } = useHookForPagination();

    const { onPrintClick, isFetchingPrintData } = usePrintDocument();

    const skip = tabName !== PrivateRoomTabsName.certificateTab;

    const bodyForCertificateList = useMemo(() => {
        const { lastEditDate, ...rest } = filtersForCertificates as TableFilters;
        const isArchive = false;

        const newFilter = {
            ...rest,
            isArchive,
            ...(lastEditDate?.[0] && { lastEditDateFrom: lastEditDate[0] }),
            ...(lastEditDate?.[1] && { lastEditDateTo: lastEditDate[1] }),
        };

        return {
            ...newFilter,
            ...(isEmptyParamObj || isRevokeRequest ? startObj : searchParamsObj),
        };
    }, [filtersForCertificates, isEmptyParamObj, searchParamsObj, isRevokeRequest]);

    const {
        data: certificatesTableData = {} as CertificatesTableResponse,
        isFetching,
        refetch,
    } = useGetCertificatesListQuery(skip ? skipToken : bodyForCertificateList);

    const { totalCount, currentPage = [], allTitles = [] } = certificatesTableData;

    const actionVariantHandler = (action: ActionVariantsType, data: CurrentPageTable) => {
        setCurrentCertificateData(data);
        actionHandler[action](data);
    };

    const printHandler = (templateId: PrintTemplates, data: CurrentPageTable) => {
        setCurrentCertificateData(data);
        onPrintClick(templateId, data);
    };

    const isDataLoading = isFetching || isFetchingDetails || isFetchingPrintData;

    const columns = returnColumnsForCertificatesTable({
        printHandler,
        actionVariantHandler,
        allTitles,
    });

    useEffect(() => {
        if (isRevokeRequest) {
            refetch();
        }
        setIsRevokeRequest(false);
    }, [isRevokeRequest, refetch, setIsRevokeRequest]);

    return (
        <React.Fragment>
            <CustomTable
                className={styles.certificatesTable}
                dataSource={currentPage}
                columns={columns}
                currentTablePage={currentTablePage}
                currentTableSize={currentTableSize}
                currentSortColumn={currentSortColumn}
                currentSortOrder={currentSortOrder}
                totalCount={totalCount}
                loading={{ spinning: isDataLoading }}
                filterName={LOCAL_STORAGE.filtersForCertificates}
                scrollX={900}
                locale={{
                    emptyText: isDataLoading ? (
                        <span style={{ display: 'inline-block', height: 100 }} />
                    ) : (
                        <Empty description='Нет данных' />
                    ),
                }}
            />
            <CustomModal
                open={isOpenModal}
                className={styles.modal}
                setOpenModal={setIsOpenModal}
                isClose={isOpenModal}
                style={{ maxWidth: 520 }}
                width='fit-content'
                title={
                    modalInfo.type === ModalType.revoke ? (
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {REVOKE_CERTIFICATE.revokeModal.title}
                        </Title>
                    ) : null
                }
                footer={
                    modalInfo.type === ModalType.generateKeys ? (
                        <CustomButton key={BUTTON_TEXT.continue} onClick={generateKeys}>
                            {BUTTON_TEXT.continue}
                        </CustomButton>
                    ) : null
                }
            >
                {modalInfo.type === ModalType.revoke ? (
                    <RevokeReasonModal
                        revokeHandler={revokeRequest}
                        certificateData={currentCertificateData!}
                        setReason={setCurrentRevokeReason}
                    />
                ) : (
                    <ViewAnswerModal
                        isError={modalInfo.isError}
                        text={modalInfo.text}
                        title={modalInfo.title}
                        details={
                            modalInfo.title === ModalTextVariant.answer
                                ? currentCertificateData.details
                                : undefined
                        }
                    />
                )}
            </CustomModal>
        </React.Fragment>
    );
};
