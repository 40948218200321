import { joinTitleSerialNumber } from '../join-title-serial-number';
import { transformDateToLocale } from '../transform-date-to-locale';

import { CERTIFICATES_STATUS_INFO } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { CertificateData } from '~/utils/transform-certificates-data/type';

// Утилита, которая трансфортирует данные для отображения в таблице с перечнем сертификатов

export const transformCertificateData = (currentPage: CertificateData[]) => {
    const titleHashmap: Record<string, string> = {};

    const transformedCurrentPage = currentPage.map((certificate) => {
        const { lifeStatus, lastEditDate, title, status } = certificate;
        const transformedTitle = joinTitleSerialNumber(certificate);

        titleHashmap[title] = transformedTitle;

        const { color, titleStatus, actionsVariant, documents } = CERTIFICATES_STATUS_INFO[status];
        const keyVariant = {
            [LifeStatus.preApplication]:
                lifeStatus === LifeStatus.preApplication ? certificate.lkRequestId.toString() : '',
            [LifeStatus.application]: `${lastEditDate}+${title}`,
            [LifeStatus.certificate]:
                lifeStatus === LifeStatus.certificate ? certificate.certificateId : '',
            [LifeStatus.revocation]:
                lifeStatus === LifeStatus.revocation ? certificate.certificateId : '',
        };

        return {
            ...certificate,
            title: transformedTitle,
            key: keyVariant[lifeStatus],
            status: { color, titleStatus, statusCode: status },
            lastEditDate: transformDateToLocale(lastEditDate),
            actionsVariant,
            documents,
        };
    });

    return { transformedCurrentPage, titleHashmap };
};
