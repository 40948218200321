import { Alert, Typography } from 'antd';

import styles from './check-document-title.module.css';

import { FORMS_DATA } from '~/constants/forms-data';
import { SignDocumentText } from '~/constants/sign-document';
import { CustomRow } from '~/shared/custom-row';

export const CheckDocumentTitle = () => (
    <CustomRow columnSpan={24} rowGutter={[0, 12]}>
        <Typography.Title className={styles.title} level={5}>
            {FORMS_DATA.checkDocumentForm.title}
        </Typography.Title>
        <Alert
            type='warning'
            showIcon={true}
            message={SignDocumentText.attention}
            className={styles.alert}
        />
    </CustomRow>
);
