export enum EndpointNames {
    GET_CERTIFICATES_LIST = 'getCertificatesList',
    REVOKE_CERTIFICATES = 'revokeCertificates',
    POST_CERTIFICATES = 'postCertificates',
    POST_DRAFT = 'postCreateDraft',
    GET_DRAFT = 'getDraft',
    REMOVE_DRAFT = 'removeDraft',
    APPLY_CERTIFICATES = 'applyCertificates',
    GET_CERTIFICATE_PKI_DETAILS = 'getCertificatePkiDetails',

    SEND_TO_ARCHIVE = 'sendToArchive',
    UNARCHIVE = 'unarchive',

    POST_ENSURE_SESSION = 'postEnsureSession',
    GET_ESIA_LOGIN = 'getEsiaLogin',
    POST_LOGIN_BY_ESIA = 'postLoginByEsia',
    POST_LOGOUT = 'postLogout',
    POST_LOGOUT_ESIA = 'postLogoutEsia',
    REFRESH_TOKEN = 'refreshToken',
    AUTH_BY_CERT = 'authByCert',

    GET_TEMPLATES = 'getTemplates',
    GET_PRINTED_TEMPLATE = 'getPrintedTemplate',
    GET_INIT_FOR_PRINT = 'getInitForPrint',

    GET_SVS_POLICY = 'getSVSPolicy',
    CHECK_VERIFY_CERTIFICATE = 'checkVerifyCertificate',
    CHECK_SIGNATURE_DOCUMENTS = 'checkSignatureDocuments',

    GET_PKI_USER = 'getPKIUser',
    GET_PKI_CERTIFICATE = 'getPKICertificate',
    GET_PKI_CERT_REQUEST = 'getPKICertRequest',
    GET_PKI_REVOKE = 'getPKIRevoke',

    GET_USER_INFO = 'getUserInfo',
    GET_REGION_INFO = 'getRegionInfo',
    GET_USER_NAME = 'getUserName',
}
