import { useMemo } from 'react';

import { FormBlockLabel } from '~/components/application-tab/constants/general';
import { SignDocumentFields } from '~/components/check-and-sign-certificate/content/sign-document/types/form-types';

const mobilePlaceholder = '+7';

type UseCustomSelectProps = {
    titleForTooltip: string;
    placeholder: string | undefined;
    backgroundColorDisabled: { backgroundColor: string };
};

export const useCustomSelectProps = (
    currentValue: unknown,
    disabled: boolean,
    label?: string,
    itemName?: string,
): UseCustomSelectProps =>
    useMemo(() => {
        const titleForTooltip = typeof currentValue === 'string' && disabled ? currentValue : '';

        let placeholder = label;

        if (disabled) {
            placeholder = undefined;
        }

        if (label === FormBlockLabel.mobile) {
            placeholder = mobilePlaceholder;
        }

        if (itemName === SignDocumentFields.thumbprint) {
            placeholder = 'Подпись';
        }

        const backgroundColorDisabled = {
            backgroundColor: disabled ? 'var(--backgroundDisabled)' : '',
        };

        return {
            titleForTooltip,
            placeholder,
            backgroundColorDisabled,
        };
    }, [currentValue, disabled, label, itemName]);
