import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Space } from 'antd';
import Link from 'antd/es/typography/Link';
import { FormInstance } from 'antd/lib';
import classNames from 'classnames';

import { UserInfoTemp } from '../types/form-types';

import styles from '../application-tab.module.css';

import { AddressFormBlock } from '~/components/application-tab/address-form-block';
import {
    NotificationTextPartOne,
    NotificationTextPartTwo,
} from '~/components/application-tab/constants/general';
import { DocumentsFormBlock } from '~/components/application-tab/documents-form-block';
import { MainFormBlock } from '~/components/application-tab/main-form-block';
import { OtherFormBlock } from '~/components/application-tab/other-form-block';
import { TOOLTIP_TEXT_FOR_FORM } from '~/components/certificates-table/constants/general';
import { BUTTON_TEXT } from '~/constants/button';
import { Doc, DOCUMENTS_PART_FORM, PRIVATE_ROOM_SUBTITLE } from '~/constants/private-room';
import { ROUTES } from '~/constants/routes';
import { stepName } from '~/constants/step-name';
import { PrivateRoomTabsName } from '~/pages/private-room/constants/tabs-name';
import { useGetRegionInfoQuery } from '~/rtk-queries/endpoints/user-info';
import { CountriesResponse, UserInfoResponse } from '~/rtk-queries/types/private-room';
import { CustomButton } from '~/shared/custom-button';
import { FormTooltip } from '~/shared/form-components/form-tooltip';
import { TextLinkEnd } from '~/shared/text-link-end';

type FormBodyProps = {
    step: string;
    form: FormInstance;
    docType: Doc;
    onSaveDraft: () => void;
    onSave: () => void;
    cleanupAfterCertificateCreation: (form: FormInstance) => void;
    setStep: (step: stepName) => void;
    userInfoResponse?: UserInfoResponse;
    draft?: UserInfoTemp | null;
    disableButton?: boolean;
};

export const FormBody = ({
    step,
    form,
    docType,
    onSaveDraft,
    onSave,
    cleanupAfterCertificateCreation,
    setStep,
    userInfoResponse,
    draft,
    disableButton,
}: FormBodyProps): JSX.Element => {
    const navigate = useNavigate();
    const { data: countriesData = {} as CountriesResponse } = useGetRegionInfoQuery();
    const onBack = () => {
        cleanupAfterCertificateCreation(form);
        setStep(stepName.one);
        navigate(`${ROUTES.privateRoom}/${PrivateRoomTabsName.applicationTab}`);
    };

    return (
        <div
            className={classNames(styles.applicationTabBlocks, {
                [styles.active]: step === stepName.two,
            })}
        >
            <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topRight'>
                <Button type='default' className={styles.backButton} onClick={onBack}>
                    <ArrowLeftOutlined className={styles.topArrowIcon} />
                    <span className={styles.applicationTitle}>{PRIVATE_ROOM_SUBTITLE.data}</span>
                </Button>
            </FormTooltip>
            <Space className={styles.changeDataNotefication}>
                <TextLinkEnd screenTitle={NotificationTextPartOne} />
                <span className={styles.noteficationText}>{NotificationTextPartTwo}</span>
            </Space>
            <MainFormBlock form={form} data={userInfoResponse} countriesData={countriesData} />
            <DocumentsFormBlock
                form={form}
                documentsTemplate={DOCUMENTS_PART_FORM[docType]}
                docType={docType}
                draft={draft?.document}
                data={userInfoResponse}
                disabled={disableButton}
            />
            {userInfoResponse?.userInfo?.address && (
                <AddressFormBlock
                    form={form}
                    data={userInfoResponse}
                    countriesData={countriesData}
                />
            )}
            {userInfoResponse?.userInfo?.other && (
                <OtherFormBlock form={form} data={userInfoResponse} />
            )}

            <Flex wrap={true} vertical={true} className={styles.buttonsRow} gap={32}>
                <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topLeft'>
                    <Link className={styles.backButton} onClick={onBack}>
                        <ArrowLeftOutlined className={styles.arrowIcon} />
                        {BUTTON_TEXT.back}
                    </Link>
                </FormTooltip>
                <Space size={32}>
                    <CustomButton
                        disabled={disableButton}
                        className='updatePageButton'
                        onClick={onSaveDraft}
                    >
                        {BUTTON_TEXT.saveDraft}
                    </CustomButton>

                    <CustomButton
                        disabled={disableButton}
                        className='applicationTabButton'
                        onClick={onSave}
                        type='primary'
                    >
                        {BUTTON_TEXT.sendRequest}
                    </CustomButton>
                </Space>
            </Flex>
        </div>
    );
};
